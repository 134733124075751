import getVideoId from "get-video-id";
import pluralize from 'pluralize';

export const getEmbedCodeByUrl = (url) => {
  if (typeof url !== "string") {
    return false;
  }

  const videoId = getVideoId(url);

  switch (true) {
    case videoId.service === "youtube":
      return (
        '<iframe src="https://www.youtube.com/embed/' +
        videoId.id +
        '" border="0" frameborder="0" allowfullscreen></iframe>'
      );
      break;
    case url.includes("matterport"):
    case url.includes("virtuance"):
    case url.includes("boxbrownie"):
    case videoId.service === "vimeo":
    default:
      // Vimeo: Can get the ID but not the hash parameter (?h=12345) which is needed for videos not publicly listed.
      // Just use full URL for embed code for now, but could select hash parameter if we need finer control.
      // return '<iframe src="https://player.vimeo.com/video/'+videoId.id+'?h=d2c4fd742d" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
      return (
        '<iframe src="' +
        url +
        '" border="0" frameborder="0" allowfullscreen></iframe>'
      );
      break;
  }
};

export const getNeighborhoodNavSubtitle = (
  types = "",
  price = "",
  neigborhoodStatus = "",
  plural = false,
) => {
  if (!neigborhoodStatus || neigborhoodStatus === "coming_soon" || neigborhoodStatus === "coming_soon_plain") {
    return '';
  }

  if (neigborhoodStatus === "under_development") {
    return "Under Development – Stay Tuned";
  }

  if (neigborhoodStatus === "sold_out") {
    return "Sold Out";
  }

  let typeList = types === "" ? "" : types.split(",");

  if (typeList.length === 0) {
    return price ? "from $" + price : "";
  }

  if (typeList.length === 1) {
    if (plural === true && typeList[0].toLowerCase().includes('-story')) {
      const words = typeList[0].trim().split(' ');
      const lastWord = words[words.length - 1];
      const lastWordPlural = pluralize(lastWord);
      const newTypeList = typeList[0].replace(new RegExp(lastWord, 'g'), lastWordPlural);

      return price ? newTypeList + " from $" + price : "Active";
    }
    return price ? typeList + " from $" + price : "Active";
  }

  if (typeList.length > 1) {
    let finalName = typeList.pop();
    return price
      ? typeList.join(", ") + " & " + finalName + " from $" + price
      : "Active";
  }
};

export const getRoundedPrice = (price, multiple = 5000) => {
  if (!price || isNaN(price)) {
    return "";
  }

  let roundedNum = Math.ceil(price / multiple) * multiple;
  let abbreviatedNum =
    roundedNum > 995000 ? roundedNum / 1000000 : roundedNum / 1000;
  let suffix = roundedNum > 995000 ? "m" : "k";

  return abbreviatedNum + suffix;
};

export const flatListToHierarchical = (
  data = [],
  { idKey = "id", parentKey = "parentId", childrenKey = "children" } = {}
) => {
  const tree = [];
  const childrenOf = {};
  data.forEach((item) => {
    const newItem = { ...item };
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem);
  });
  return tree;
};

export const filterWPContent = (string) => {
  try {
    let url = new URL(string);
    if (
      url.hostname.includes("livebouldercreek.com") &&
      !url.hostname.includes("staging") &&
      !url.hostname.includes("wp.")
    ) {
      url.hostname = "wp." + url.hostname;
    }
    return url.toString();
  } catch (e) {
    if (string?.startsWith("/wp-content") || string?.startsWith("wp-content")) {
      return "https://wp.livebouldercreek.com/" + string.replace(/^\/?/, "");
    } else {
      return string;
    }
  }
};

export const getImageSrc = (image, sizeName) => {
  const full = image?.sourceUrl;

  const preferred = image?.mediaDetails?.sizes?.filter(function (size) {
    return size.name === this;
  }, sizeName);

  const srcString =
    preferred && preferred.length ? preferred[0].sourceUrl : full;

  return filterWPContent(srcString);
};

export const addFullWidthSupport = () => {
  const style = document.createElement("style");

  const scrollbarWidth = window.innerWidth - document.body.clientWidth;

  style.innerHTML = `
    .container-full-width {
      width: calc(100vw - 15px);
      width: calc(100vw - ${scrollbarWidth}px);
      margin-inline-start: 50%;
      transform: translateX(-50%);
    }
  `;

  document.head.appendChild(style);
};

export const getNeighborhoodStatusLabel = (statusValue) => {
  const statusLabels = {
    under_development: "Under Development – Stay Tuned",
    coming_soon_plain: "Coming Soon",
    coming_soon: "New Floorplans Coming Soon",
    now_preselling: "Now Preselling",
    model_open: "Model Home Now Open",
    new_homesites: "New Homesites Now Available",
    open: "",
    closeout: "Last Chance: Almost Sold Out",
    sold_out: "Sold Out",
  };

  return statusLabels[statusValue] || "";
};

export const kebabCase = (string) =>
  string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase()
    .replace(/-{2,}/g, "-");

export const neighborhoodFeaturesIcon = (category) => {
  switch (category) {
    case "location":
      return "/img/icon-amenities-location.svg";
      break;
    case "active_lifestyle":
      return "/img/icon-amenities-active-lifestyle.svg";
      break;
    case "scenery":
      return "/img/icon-amenities-scenery.svg";
      break;
    case "shopping_dinning":
      return "/img/icon-amenities-shopping.svg";
      break;
    case "low_maintenance":
      return "/img/icon-amenities-low-maint.svg";
      break;
  }
};

export const getCollectionsByNeighborhood = (
  neighborhoodSlug,
  plans = [],
  homes = [],
  models = [],
  neighborhoodCollections = []
) => {
  const collections = [];

  if (
    Array.isArray(neighborhoodCollections) &&
    neighborhoodCollections.length
  ) {
    neighborhoodCollections.forEach((item) => {
      if (item && collections.indexOf(item) === -1) {
        collections.push(item);
      }
    });
  }

  const modelCollections = Array.isArray(models)
    ? models.reduce((collections, node) => {
        if (
          node?.modelDetails?.floorplan?.floorplanDetails?.collection &&
          node?.modelDetails?.floorplan?.floorplanDetails?.collection !==
            "null" &&
          neighborhoodSlug === node?.modelDetails?.neighborhood?.slug &&
          collections.indexOf(
            node?.modelDetails?.floorplan?.floorplanDetails?.collection
          ) === -1
        ) {
          collections.push(
            node?.modelDetails?.floorplan?.floorplanDetails?.collection
          );
        } else if (
          node?.collection?.collection &&
          node?.collection?.collection !== "null" &&
          neighborhoodSlug === node?.modelDetails?.neighborhood?.slug &&
          collections.indexOf(node?.collection?.collection) === -1
        ) {
          collections.push(node?.collection?.collection);
        }
        return collections;
      }, collections)
    : [];

  const homeCollections = Array.isArray(homes)
    ? homes.reduce((collections, node) => {
        if (
          node?.qmiHomeDetails?.floorplan?.floorplanDetails?.collection &&
          node?.qmiHomeDetails?.floorplan?.floorplanDetails?.collection !==
            "null" &&
          neighborhoodSlug === node?.qmiHomeDetails?.neighborhood?.slug &&
          collections.indexOf(
            node?.qmiHomeDetails?.floorplan?.floorplanDetails?.collection
          ) === -1
        ) {
          collections.push(
            node?.qmiHomeDetails?.floorplan?.floorplanDetails?.collection
          );
        } else if (
          node?.collection?.collection &&
          node?.collection?.collection !== "null" &&
          neighborhoodSlug === node?.qmiHomeDetails?.neighborhood?.slug &&
          collections.indexOf(node?.collection?.collection) === -1
        ) {
          collections.push(node?.collection?.collection);
        }
        return collections;
      }, collections)
    : [];

  const planCollections = Array.isArray(plans)
    ? plans.reduce((collections, node) => {
        if (
          node?.floorplanDetails?.collection &&
          node?.floorplanDetails?.collection !== "null" &&
          neighborhoodSlug === node?.floorplanDetails?.neighborhood?.slug &&
          collections.indexOf(node?.floorplanDetails?.collection) === -1
        ) {
          collections.push(node?.floorplanDetails?.collection);
        }
        return collections;
      }, collections)
    : [];

  return collections;
};

export const getCollectionIcon = (collection) => {
  if (collection?.toLowerCase().includes("easyhouse")) {
    return {
      collection: collection,
      collectionTitle: "easyHouse®",
      sourceUrl: "/img/easyhouse-icon.svg",
      alt: "easyHouse",
      id: "easyhouse",
      textClass: "text-bc-ezh-blue",
      link: "/our-homes/home-collections/easyhouse/",
    };
  }
  if (collection?.toLowerCase().includes("wee-cottage")) {
    return {
      collection: collection,
      collectionTitle: "wee-Cottage®",
      sourceUrl: "/img/wee-cottage-icon.svg",
      alt: "wee-Cottage",
      id: "wee-cottage",
      textClass: "text-bc-wc-green",
      link: "/our-homes/home-collections/wee-cottage/",
    };
  }
  if (
    collection?.toLowerCase().includes("limited edition") ||
    collection?.toLowerCase().includes("limited-edition")
  ) {
    return {
      collection: collection,
      collectionTitle: "Limited Edition",
      sourceUrl: "/img/limited_edition-icon.svg",
      alt: "Limited Edition",
      id: "limited-edition",
      textClass: "text-bc-le-gray",
      link: "/our-homes/home-collections/limitededition/",
    };
  }
  if (
    collection?.toLowerCase().includes("new beginnings") ||
    collection?.toLowerCase().includes("new-beginnings")
  ) {
    return {
      collection: collection,
      collectionTitle: "New Beginnings",
      sourceUrl: "/img/new-beginnings-icon.svg",
      alt: "New Beginnings",
      id: "new-beginnings",
      textClass: "text-bc-nb-blue",
      // No New Beginnings collection page yet
      // link: "/our-homes/home-collections/new-beginnings/",
    };
  }
};

export const getMultipleRandom = (arr, num) => {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());

  return shuffled.slice(0, num);
};

export const getRandomUniqueItem = (array, destinationArr, counter = 0) => {
  let items = [...array];
  const randomItem = getMultipleRandom(items, 1)[0];

  if (
    counter < 3 &&
    destinationArr.some((item) => item.databaseId === randomItem.databaseId)
  ) {
    items = items.filter((item) => item.databaseId !== randomItem.databaseId);

    return getRandomUniqueItem(items, destinationArr, counter++);
  }

  return randomItem;
};

export const getPopularNeighborhoods = (
  selectedNeighborhoods,
  allNeighborhoods,
  currentId
) => {
  // return allNeighborhoods;

  if (!Array.isArray(allNeighborhoods)) {
    return selectedNeighborhoods;
  }

  const filteredAllNeighborhoods = allNeighborhoods.filter(
    (item) => currentId !== item.databaseId
  );

  if (
    Array.isArray(selectedNeighborhoods) &&
    selectedNeighborhoods.length > 1
  ) {
    return selectedNeighborhoods;
  }

  if (
    Array.isArray(selectedNeighborhoods) &&
    selectedNeighborhoods.length === 1
  ) {
    const items = [...selectedNeighborhoods];
    const randomItem = getRandomUniqueItem(filteredAllNeighborhoods, items);
    items.push(randomItem);
    return items;
  }

  const items = getMultipleRandom(filteredAllNeighborhoods, 2);
  // let counter = 0;

  return items;
};

export const getPopularPlans = (currentPlan, allPlans) => {
  const unavailableStatus = ["Coming Soon", "Sold Out"];

  const filteredPlans = allPlans.filter(
    (item) =>
      currentPlan.databaseId !== item.databaseId &&
      currentPlan.floorplanDetails.collection ===
        item.floorplanDetails.collection &&
      !unavailableStatus.includes(item.productStatus.statusMessage[1])
  );

  return getMultipleRandom(filteredPlans, 3);
};

export const detailMinMax = (min, max, spaces = true) => {
  if (spaces) {
    return min && max ? min + " - " + max : min ? min : max;
  } else {
    return min && max ? min + "-" + max : min ? min : max;
  }
};

export const hideFilterSectionIfEmpty = () => {
  // Must be used in ClientOnly, or after DOM has mounted.
  const sections = document.querySelectorAll(
    ".new-home-search-grid-container section"
  );

  sections.forEach((section) => {
    const items = section.querySelectorAll("div.item");

    let visibleItemCount = 0;

    items.forEach((item) => {
      const itemIsVisible = getComputedStyle(item).display !== "none";

      if (itemIsVisible) {
        visibleItemCount++;
      }
    });

    section.style.display = visibleItemCount ? "block" : "none";
  });
};

export const updatePlanCount = () => {
  const items = document.querySelectorAll(
    ".new-home-search-grid-container .item"
  );
  const planCountElement = document.getElementById("plan-count");

  let visibleItemCount = 0;

  items.forEach((item) => {
    const itemIsVisible = getComputedStyle(item).display !== "none";

    if (itemIsVisible) {
      visibleItemCount++;
    }
  });

  planCountElement.innerHTML = visibleItemCount;
};

// Generate a random string of specified length
export const generateRandomString = (
  length,
  characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
) => {
  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

// ToDo: Refactor this
export const convertDateFormat = (originalDateString) => {
  if (!originalDateString) {
    return originalDateString;
  }

  // Create a new Date object with the original date string
  const originalDate = new Date(originalDateString);

  // Create an array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the month, day, and year from the Date object
  const month = monthNames[originalDate.getMonth()];
  const day = originalDate.getDate();
  const year = originalDate.getFullYear();

  // Create the new date string in the desired format
  const newDate = `${month} ${day}, ${year}`;

  return newDate;
};

export const numMap = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
  eleven: 11,
  twelve: 12,
  thirteen: 13,
  fourteen: 14,
  fifteen: 15,
  sixteen: 16,
  seventeen: 17,
  eighteen: 18,
  nineteen: 19,
  twenty: 20,
};

export const isValidJSON = (string) => {
  try {
    JSON.parse(string);
  } catch (error) {
    return false;
  }
  return true;
};

export const trimQuotes = (string) => {
  return string.replace(/^\"|\\\"|\"$|\\\"$/g, "");
};

export const generateLogarithmicArrayIntegers = (
  min,
  max,
  count,
  base = 10,
) => {
  const array = new Set(); // Using a Set to avoid duplicate values
  const logMin = Math.log(min) / Math.log(base);
  const logMax = Math.log(max) / Math.log(base);
  const step = (logMax - logMin) / (count - 1);

  for (let i = 0; i < count; i++) {
    const logValue = logMin + step * i;
    const roundedValue = Math.round(Math.pow(base, logValue));
    array.add(roundedValue);
  }

  return Array.from(array);
};
