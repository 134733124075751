import { default as energy_45green_45practicesbXccnoQzNKMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journeyFunXGYil17Meta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderjQoAdCZDXUMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexyk4jcEGhgWMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teamEJd4UPyuJ2Meta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderFleu67Oud5Meta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcesDnn2P5M59lMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93ewN2tv0tkjMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexOgyOvdwnbeMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexNdN4wJfEgaMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_939RydySmGiPMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexxN2uHj3CoxMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as ask_45a_45questionQ2ftsqIKl3Meta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesWiNiUjxA3cMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqD6A0LLdIliMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesQ7hKhfgyCiMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexB88Dy1iECSMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsjLEMpWkesyMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offerst2KPv2NjMYMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexxIRQSRfSUNMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexjvNx8u5EeQMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexiocgPMGJFMMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as index0UUwO4O2rbMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as indexEj0fLGHGAOMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexkEtblOWEf1Meta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexPlOQ0G285oMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as land_45acquisitioneLu3lUNCeaMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placeU42qke5AOSMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenance5bhZZNPj4nMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93EgbF0cRzvgMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexiP4THn0LflMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexkOlmamrXvaMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessVbrr8e8gz1Meta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93ea2wCJbw9BMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoritesJPRcGf3RSEMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchvTBLYrQQV8Meta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhouseli1gVwnVStMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededition7iMiVKgu7ZMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottageDhhXF4GyHtMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexbLO2q7hZPKMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsVjzft7bVKXMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45us2dTH8INssmMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacyterms9Y7V2xwSbwMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandboxTLMoyZbGwPMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93G5nfnOx9BfMeta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stubZ0Gw5SX0e7Meta } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubZ0Gw5SX0e7 } from "/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesbXccnoQzNKMeta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesbXccnoQzNKMeta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesbXccnoQzNKMeta || {},
    alias: energy_45green_45practicesbXccnoQzNKMeta?.alias || [],
    redirect: energy_45green_45practicesbXccnoQzNKMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journeyFunXGYil17Meta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journeyFunXGYil17Meta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journeyFunXGYil17Meta || {},
    alias: fun_45part_45journeyFunXGYil17Meta?.alias || [],
    redirect: fun_45part_45journeyFunXGYil17Meta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderjQoAdCZDXUMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderjQoAdCZDXUMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderjQoAdCZDXUMeta || {},
    alias: homesafe_45colorado_45master_45builderjQoAdCZDXUMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderjQoAdCZDXUMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexyk4jcEGhgWMeta?.name ?? "about-us",
    path: indexyk4jcEGhgWMeta?.path ?? "/about-us",
    meta: indexyk4jcEGhgWMeta || {},
    alias: indexyk4jcEGhgWMeta?.alias || [],
    redirect: indexyk4jcEGhgWMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teamEJd4UPyuJ2Meta?.name ?? "about-us-our-team",
    path: our_45teamEJd4UPyuJ2Meta?.path ?? "/about-us/our-team",
    meta: our_45teamEJd4UPyuJ2Meta || {},
    alias: our_45teamEJd4UPyuJ2Meta?.alias || [],
    redirect: our_45teamEJd4UPyuJ2Meta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderFleu67Oud5Meta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderFleu67Oud5Meta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderFleu67Oud5Meta || {},
    alias: preferred_45lenderFleu67Oud5Meta?.alias || [],
    redirect: preferred_45lenderFleu67Oud5Meta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcesDnn2P5M59lMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcesDnn2P5M59lMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcesDnn2P5M59lMeta || {},
    alias: realtor_45resourcesDnn2P5M59lMeta?.alias || [],
    redirect: realtor_45resourcesDnn2P5M59lMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93ewN2tv0tkjMeta?.name ?? "blog-post",
    path: _91post_93ewN2tv0tkjMeta?.path ?? "/blog/:post()",
    meta: _91post_93ewN2tv0tkjMeta || {},
    alias: _91post_93ewN2tv0tkjMeta?.alias || [],
    redirect: _91post_93ewN2tv0tkjMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexOgyOvdwnbeMeta?.name ?? "blog-category-category",
    path: indexOgyOvdwnbeMeta?.path ?? "/blog/category/:category()",
    meta: indexOgyOvdwnbeMeta || {},
    alias: indexOgyOvdwnbeMeta?.alias || [],
    redirect: indexOgyOvdwnbeMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNdN4wJfEgaMeta?.name ?? "blog",
    path: indexNdN4wJfEgaMeta?.path ?? "/blog",
    meta: indexNdN4wJfEgaMeta || {},
    alias: indexNdN4wJfEgaMeta?.alias || [],
    redirect: indexNdN4wJfEgaMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_939RydySmGiPMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_939RydySmGiPMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_939RydySmGiPMeta || {},
    alias: _91_46_46_46slug_939RydySmGiPMeta?.alias || [],
    redirect: _91_46_46_46slug_939RydySmGiPMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexxN2uHj3CoxMeta?.name ?? "campaigns",
    path: indexxN2uHj3CoxMeta?.path ?? "/campaigns",
    meta: indexxN2uHj3CoxMeta || {},
    alias: indexxN2uHj3CoxMeta?.alias || [],
    redirect: indexxN2uHj3CoxMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionQ2ftsqIKl3Meta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionQ2ftsqIKl3Meta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionQ2ftsqIKl3Meta || {},
    alias: ask_45a_45questionQ2ftsqIKl3Meta?.alias || [],
    redirect: ask_45a_45questionQ2ftsqIKl3Meta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesWiNiUjxA3cMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesWiNiUjxA3cMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesWiNiUjxA3cMeta || {},
    alias: career_45opportunitiesWiNiUjxA3cMeta?.alias || [],
    redirect: career_45opportunitiesWiNiUjxA3cMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqD6A0LLdIliMeta?.name ?? "contact-us-faq",
    path: faqD6A0LLdIliMeta?.path ?? "/contact-us/faq",
    meta: faqD6A0LLdIliMeta || {},
    alias: faqD6A0LLdIliMeta?.alias || [],
    redirect: faqD6A0LLdIliMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourcesQ7hKhfgyCiMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourcesQ7hKhfgyCiMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourcesQ7hKhfgyCiMeta || {},
    alias: homeowner_45resourcesQ7hKhfgyCiMeta?.alias || [],
    redirect: homeowner_45resourcesQ7hKhfgyCiMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexB88Dy1iECSMeta?.name ?? "contact-us",
    path: indexB88Dy1iECSMeta?.path ?? "/contact-us",
    meta: indexB88Dy1iECSMeta || {},
    alias: indexB88Dy1iECSMeta?.alias || [],
    redirect: indexB88Dy1iECSMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsjLEMpWkesyMeta?.name ?? "contact-us-our-locations",
    path: our_45locationsjLEMpWkesyMeta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsjLEMpWkesyMeta || {},
    alias: our_45locationsjLEMpWkesyMeta?.alias || [],
    redirect: our_45locationsjLEMpWkesyMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offerst2KPv2NjMYMeta?.name ?? "current-offers",
    path: current_45offerst2KPv2NjMYMeta?.path ?? "/current-offers",
    meta: current_45offerst2KPv2NjMYMeta || {},
    alias: current_45offerst2KPv2NjMYMeta?.alias || [],
    redirect: current_45offerst2KPv2NjMYMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexxIRQSRfSUNMeta?.name ?? "events-event",
    path: indexxIRQSRfSUNMeta?.path ?? "/events/:event()",
    meta: indexxIRQSRfSUNMeta || {},
    alias: indexxIRQSRfSUNMeta?.alias || [],
    redirect: indexxIRQSRfSUNMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjvNx8u5EeQMeta?.name ?? "events",
    path: indexjvNx8u5EeQMeta?.path ?? "/events",
    meta: indexjvNx8u5EeQMeta || {},
    alias: indexjvNx8u5EeQMeta?.alias || [],
    redirect: indexjvNx8u5EeQMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexiocgPMGJFMMeta?.name ?? "homes-location-community",
    path: indexiocgPMGJFMMeta?.path ?? "/homes/:location()/:community()",
    meta: indexiocgPMGJFMMeta || {},
    alias: indexiocgPMGJFMMeta?.alias || [],
    redirect: indexiocgPMGJFMMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: index0UUwO4O2rbMeta?.name ?? "homes-location-community-models-model",
    path: index0UUwO4O2rbMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: index0UUwO4O2rbMeta || {},
    alias: index0UUwO4O2rbMeta?.alias || [],
    redirect: index0UUwO4O2rbMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEj0fLGHGAOMeta?.name ?? "homes-location-community-plans-plan",
    path: indexEj0fLGHGAOMeta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: indexEj0fLGHGAOMeta || {},
    alias: indexEj0fLGHGAOMeta?.alias || [],
    redirect: indexEj0fLGHGAOMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkEtblOWEf1Meta?.name ?? "homes-location-community-qmi-home",
    path: indexkEtblOWEf1Meta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexkEtblOWEf1Meta || {},
    alias: indexkEtblOWEf1Meta?.alias || [],
    redirect: indexkEtblOWEf1Meta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexPlOQ0G285oMeta?.name ?? "index",
    path: indexPlOQ0G285oMeta?.path ?? "/",
    meta: indexPlOQ0G285oMeta || {},
    alias: indexPlOQ0G285oMeta?.alias || [],
    redirect: indexPlOQ0G285oMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitioneLu3lUNCeaMeta?.name ?? "land-acquisition",
    path: land_45acquisitioneLu3lUNCeaMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitioneLu3lUNCeaMeta || {},
    alias: land_45acquisitioneLu3lUNCeaMeta?.alias || [],
    redirect: land_45acquisitioneLu3lUNCeaMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placeU42qke5AOSMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placeU42qke5AOSMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placeU42qke5AOSMeta || {},
    alias: brents_45placeU42qke5AOSMeta?.alias || [],
    redirect: brents_45placeU42qke5AOSMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenance5bhZZNPj4nMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenance5bhZZNPj4nMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenance5bhZZNPj4nMeta || {},
    alias: experience_45lower_45maintenance5bhZZNPj4nMeta?.alias || [],
    redirect: experience_45lower_45maintenance5bhZZNPj4nMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93EgbF0cRzvgMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93EgbF0cRzvgMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93EgbF0cRzvgMeta || {},
    alias: _91testimonial_93EgbF0cRzvgMeta?.alias || [],
    redirect: _91testimonial_93EgbF0cRzvgMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexiP4THn0LflMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexiP4THn0LflMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexiP4THn0LflMeta || {},
    alias: indexiP4THn0LflMeta?.alias || [],
    redirect: indexiP4THn0LflMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkOlmamrXvaMeta?.name ?? "lifestyle-reviews",
    path: indexkOlmamrXvaMeta?.path ?? "/lifestyle/reviews",
    meta: indexkOlmamrXvaMeta || {},
    alias: indexkOlmamrXvaMeta?.alias || [],
    redirect: indexkOlmamrXvaMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessVbrr8e8gz1Meta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessVbrr8e8gz1Meta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessVbrr8e8gz1Meta || {},
    alias: whats_45lifefullnessVbrr8e8gz1Meta?.alias || [],
    redirect: whats_45lifefullnessVbrr8e8gz1Meta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ea2wCJbw9BMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93ea2wCJbw9BMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93ea2wCJbw9BMeta || {},
    alias: _91_46_46_46slug_93ea2wCJbw9BMeta?.alias || [],
    redirect: _91_46_46_46slug_93ea2wCJbw9BMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesJPRcGf3RSEMeta?.name ?? "my-favorites",
    path: my_45favoritesJPRcGf3RSEMeta?.path ?? "/my-favorites",
    meta: my_45favoritesJPRcGf3RSEMeta || {},
    alias: my_45favoritesJPRcGf3RSEMeta?.alias || [],
    redirect: my_45favoritesJPRcGf3RSEMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchvTBLYrQQV8Meta?.name ?? "new-home-search",
    path: new_45home_45searchvTBLYrQQV8Meta?.path ?? "/new-home-search",
    meta: new_45home_45searchvTBLYrQQV8Meta || {},
    alias: new_45home_45searchvTBLYrQQV8Meta?.alias || [],
    redirect: new_45home_45searchvTBLYrQQV8Meta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhouseli1gVwnVStMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhouseli1gVwnVStMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhouseli1gVwnVStMeta || {},
    alias: easyhouseli1gVwnVStMeta?.alias || [],
    redirect: easyhouseli1gVwnVStMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededition7iMiVKgu7ZMeta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededition7iMiVKgu7ZMeta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededition7iMiVKgu7ZMeta || {},
    alias: limitededition7iMiVKgu7ZMeta?.alias || [],
    redirect: limitededition7iMiVKgu7ZMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottageDhhXF4GyHtMeta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottageDhhXF4GyHtMeta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottageDhhXF4GyHtMeta || {},
    alias: wee_45cottageDhhXF4GyHtMeta?.alias || [],
    redirect: wee_45cottageDhhXF4GyHtMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexbLO2q7hZPKMeta?.name ?? "our-homes",
    path: indexbLO2q7hZPKMeta?.path ?? "/our-homes",
    meta: indexbLO2q7hZPKMeta || {},
    alias: indexbLO2q7hZPKMeta?.alias || [],
    redirect: indexbLO2q7hZPKMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsVjzft7bVKXMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsVjzft7bVKXMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsVjzft7bVKXMeta || {},
    alias: our_45neighborhoodsVjzft7bVKXMeta?.alias || [],
    redirect: our_45neighborhoodsVjzft7bVKXMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45us2dTH8INssmMeta?.name ?? "partner-with-us",
    path: partner_45with_45us2dTH8INssmMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45us2dTH8INssmMeta || {},
    alias: partner_45with_45us2dTH8INssmMeta?.alias || [],
    redirect: partner_45with_45us2dTH8INssmMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacyterms9Y7V2xwSbwMeta?.name ?? "privacyterms",
    path: privacyterms9Y7V2xwSbwMeta?.path ?? "/privacyterms",
    meta: privacyterms9Y7V2xwSbwMeta || {},
    alias: privacyterms9Y7V2xwSbwMeta?.alias || [],
    redirect: privacyterms9Y7V2xwSbwMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandboxTLMoyZbGwPMeta?.name ?? "sandbox",
    path: sandboxTLMoyZbGwPMeta?.path ?? "/sandbox",
    meta: sandboxTLMoyZbGwPMeta || {},
    alias: sandboxTLMoyZbGwPMeta?.alias || [],
    redirect: sandboxTLMoyZbGwPMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93G5nfnOx9BfMeta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93G5nfnOx9BfMeta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93G5nfnOx9BfMeta || {},
    alias: _91_46_46_46slug_93G5nfnOx9BfMeta?.alias || [],
    redirect: _91_46_46_46slug_93G5nfnOx9BfMeta?.redirect,
    component: () => import("/codebuild/output/src1972722511/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/about-us/in-the-news/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/about-us/realtor-program/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/about-us/what-we-do/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/about-us/who-we-are/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/build-with-us/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/career-opportunities/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/email-signup/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/get-updates/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/get-updates/thank-you/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/how-to-video-library/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/lifestyle/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/locations/silver-leaf/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/login/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/lost-password/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/maintenance-items/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/organism-page/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/our-homes/home-collections/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/register/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/reset-password/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/style-guide/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  },
  {
    name: component_45stubZ0Gw5SX0e7Meta?.name ?? undefined,
    path: component_45stubZ0Gw5SX0e7Meta?.path ?? "/warranty-service-request/",
    meta: component_45stubZ0Gw5SX0e7Meta || {},
    alias: component_45stubZ0Gw5SX0e7Meta?.alias || [],
    redirect: component_45stubZ0Gw5SX0e7Meta?.redirect,
    component: component_45stubZ0Gw5SX0e7
  }
]